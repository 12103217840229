<template>
  <div class='chat-list-edit'>
    <card-header  @deleteData='deleteData' :edit='edit' :title="menuData.name"   @editTheme='editTheme'  :menuData='menuData'>
      <!-- <template #left>
        <div class='header-left' @click="toMyFolder">
            {{menuData.name}}
        </div>
      </template>
      <template #right>
          <div class="add-chat" @click="openAddChat">
            <en-icon name="tianjia" size="small"  style="color:#4895DF;"> </en-icon>
          </div>
      </template> -->
    </card-header>
    <div class='chat-list-wrap'  >
       <chat-list :simple="true"></chat-list>
    </div>
    <add-chats ref="addPerson" create-team :appendToBody='true'  @change="handleConfirmSelectPerson">
    </add-chats>
  </div>
</template>

<script>
import chatList from "@/views/chat/view/chatList";
import addChats from "@/views/chat/view/addChats";
import CardHeader from "./card-header";

export default {
  name: "ChatListEdit",
  props: {
    menuData: {
      type: Object
    },
    edit: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      showaddChats: false,
      chatDatas: [
        { name: "群聊", content: "群聊" },
        { name: "宋晓红", content: "我给你分派了个任务，你看一下" },
        { name: "宋晓红", content: "我给你分派了个任务，你看一下" },
        { name: "宋晓红", content: "我给你分派了个任务，你看一下" },
        { name: "宋晓红", content: "我给你分派了个任务，你看一下" },
        { name: "宋晓红", content: "我给你分派了个任务，你看一下" },
        { name: "宋晓红", content: "我给你分派了个任务，你看一下" },
        { name: "宋晓红", content: "我给你分派了个任务，你看一下" }]
    };
  },
  components: {
    CardHeader,
    chatList,
    addChats
  },
  computed: {

  },
  created() {
  },
  mounted() {
  },
  methods: {
    openAddChat() {
      this.showaddChats = true;// 解决穿梭框隐藏后内置dragger组件无法重新渲染的问题 // TODO en-transfer-data需要优化 1:在隐藏的时候提供可以清空内部状态的钩子
      this.$nextTick(() => {
        this.$refs.addPerson.open();
      });
    },
    deleteData() {
      this.$emit("deleteData");
    },
    editTheme() {
      this.$emit("editTheme");
    },
    toMyFolder() {
      this.$router.push({
        path: "/chat"
      });
    },
    /**
     * @description 切换聊天对象
     * @param itemData
     */
    handleChangeSession(session) {
      // 重置会话的未读数
      if (session.unread > 0) {
        this.$nextTick(() => {
          window.nim && window.nim.resetSessionUnread(session.id);
        });
      }
      this.$store.dispatch("chat/setCurrSession", session.id);
    },
    /**
     * @description 确认选择
     */
    handleConfirmSelectPerson(res, newSession) {
      this.selectedPersons = res;
      newSession ? this.handleChangeSession({ id: newSession }) : "";
      this.showaddChats = false;
      this.toMyFolder();
    }
  }
};
</script>

<style lang='scss' scoped>
.chat-list-edit{
  height: calc(100% - 50px);
  .header-left{
    font-size: 14px;
    color: #333;
    font-weight: bold;
    line-height: 40px;
    cursor: pointer;
  }
  .add-chat{
    color: #3e90fe;
    display: flex;
  }
}
.chat-list-wrap{
  height: 100%;
  overflow-y: auto;
}
.chat-item{
  display: flex;
  align-items: center;
  border-bottom: 1px solid #E8ECF2;
  height: 70px;padding-left: 20px;
  .header-right-arrow{
    width: 18px;
    height: 18px;
  }
  .message-item{
    width: 100%;
    padding-right: 20px;
    margin-left: 16px
  }
  .message-name{
    display: flex;justify-content: space-between;width: 100%;
    .name{
      color:#333333;font-size:14px;
    }
    .time{
      color:#91A1B7;font-size:12px
    }
  }
  .message-content{
    margin-top:9px;
    .content{
      font-size:12px;color:#636C78
    }
  }
}
</style>
